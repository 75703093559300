import type { FormikHelpers } from 'formik';
import { useCallback } from 'react';

import useChangePassword from 'api/hooks/auth/useChangePassword';
import { useVault } from 'containers/Services/VaultService';

import type { ChangePasswordFormModel } from './types';

export const useSubmitHandler = () => {
  const { changePassword, loading, success } = useChangePassword();
  const { clearVault } = useVault();

  const onSubmit = useCallback(
    async (
      values: ChangePasswordFormModel,
      { setFieldError }: FormikHelpers<ChangePasswordFormModel>,
    ) => {
      try {
        await changePassword({
          newPassword: values.newPassword,
          oldPassword: values.currentPassword,
        });
        await clearVault();
      } catch (e) {
        setFieldError('confirmPassword', e.message ?? 'There was an error.');
      }
    },
    [changePassword, clearVault],
  );

  return { loading, onSubmit, success };
};

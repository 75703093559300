import { parseISO } from 'date-fns';

import type { GetIncomeProfileR12Response } from 'api/queries/allocations/getIncomeProfileR12/types';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import unreachable from 'utils/unreachable';
import type { WithoutNulls } from 'utils/withoutNulls';

type Input = NonNullable<GetIncomeProfileR12Response['data']>[number];

export const normalizeIncomeProfileAsset = (
  input: WithoutNulls<Input>,
  parentId: string,
) =>
  ({
    id: `${parentId}_${kebabCaseCustom(
      input.assetName ?? '',
    )}_${kebabCaseCustom(input.custodian ?? '')}`,
    // In back this is typed as nullable string, but it's a nullable number
    income: (input.incomeStream as unknown as number | undefined | null) ?? 0,
    name: input.assetName ?? '',
    entity: input.entity ?? '',
    account: input.custodian ?? '',
    couponDate: parseISO(
      input.couponDate ?? unreachable('1970-01-01'),
    ).getTime(),
  } as const);

export type IncomeProfileAsset = ReturnType<typeof normalizeIncomeProfileAsset>;

export const normalizeIncomeProfileAssetAfterTax = (
  input: WithoutNulls<Input>,
  parentId: string,
) =>
  ({
    id: `${parentId}_${kebabCaseCustom(
      input.assetName ?? '',
    )}_${kebabCaseCustom(input.custodian ?? '')}`,
    // In back this is typed as nullable string, but it's a nullable number
    income:
      (input.incomeStreamAfterTax as unknown as number | undefined | null) ?? 0,
    name: input.assetName ?? '',
    entity: input.entity ?? '',
    account: input.custodian ?? '',
    couponDate: parseISO(
      input.monthDate ?? unreachable('1970-01-01'),
    ).getTime(),
  } as const);

export type IncomeProfileAssetAfterTax = ReturnType<
  typeof normalizeIncomeProfileAssetAfterTax
>;

import uniq from 'lodash/uniq';
import { useMemo, useState } from 'react';

import type {
  IncomeProfileAsset,
  IncomeProfileAssetAfterTax,
} from 'model/IncomeProfileAsset';
import type { IncomeProfileMonth } from 'model/IncomeProfileMonth';
import { formatMonthYear } from 'utils/dates';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

type Option = {
  label: string;
  value: string;
};
type IncomeProfileAssetSortOption = SortOption<
  IncomeProfileAsset | IncomeProfileAssetAfterTax
>;

export function getEntitiesOptions(data: IncomeProfileMonth[]) {
  const entities = uniq(
    data
      .map((dataMonth) =>
        dataMonth.assets
          .filter((item) => item.entity !== '')
          .map((item) => item.entity),
      )
      .flat(),
  );
  return [
    { value: 'default', label: 'All' },
    ...entities.map((item) => ({ value: item, label: item })),
  ];
}

export function getAccountsOptions(data: IncomeProfileMonth[]) {
  const accounts = uniq(
    data
      .map((dataMonth) =>
        dataMonth.assets
          .filter((item) => item.account !== '')
          .map((item) => item.account),
      )
      .flat(),
  );
  return [
    { value: 'default', label: 'All' },
    ...accounts.map((item) => ({ value: item, label: item })),
  ];
}

export function applyEntityFilter(
  data: IncomeProfileMonth[],
  filterEntity: string[] | undefined,
) {
  if (filterEntity !== undefined && filterEntity.length !== 0) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter((item) =>
        filterEntity.includes(item.entity),
      );
      const assetsAfterTax = dataMonth.assetsAfterTax.filter((item) =>
        filterEntity.includes(item.entity),
      );
      return {
        id: dataMonth.id,
        date: dataMonth.date,
        assets,
        assetsAfterTax,
        income: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
        incomeAfterTax: assetsAfterTax.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function applyAccountFilter(
  data: IncomeProfileMonth[],
  filterAccount: string[] | undefined,
) {
  if (filterAccount !== undefined && filterAccount.length !== 0) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter((item) =>
        filterAccount.includes(item.account),
      );
      const assetsAfterTax = dataMonth.assetsAfterTax.filter((item) =>
        filterAccount.includes(item.account),
      );
      return {
        id: dataMonth.id,
        date: dataMonth.date,
        assets,
        assetsAfterTax,
        income: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
        incomeAfterTax: assetsAfterTax.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function applyHideReceivedCoupons(
  data: IncomeProfileMonth[],
  hideReceivedCoupons: boolean,
) {
  if (!hideReceivedCoupons) {
    return data;
  }

  if (hideReceivedCoupons) {
    return data.map((dataMonth) => {
      const assets = dataMonth.assets.filter(
        (item) => item.couponDate > new Date().getTime(),
      );
      const assetsAfterTax = dataMonth.assetsAfterTax.filter(
        (item) => item.couponDate > new Date().getTime(),
      );
      return {
        id: dataMonth.id,
        date: dataMonth.date,
        assets,
        assetsAfterTax,
        income: assets.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
        incomeAfterTax: assetsAfterTax.reduce(
          (sum, asset) => Number(sum) + Number(asset.income ?? 0),
          0,
        ),
      };
    });
  }

  return data;
}

export function useDateSelection({
  incomeProfileR12DataFiltered,
  compare,
}: {
  incomeProfileR12DataFiltered: readonly IncomeProfileMonth[];
  compare: IncomeProfileAssetSortOption['compare'];
}) {
  const dateOptions = useMemo(
    (): readonly [Option, ...Option[]] => [
      {
        label: 'Summary',
        value: 'summary',
      },
      ...incomeProfileR12DataFiltered.map(({ date }) => ({
        value: `${date}`,
        label: `${formatMonthYear(date)}`,
      })),
    ],
    [incomeProfileR12DataFiltered],
  );

  const [selectedDate, setSelectedDate] = useState(dateOptions[0].value);

  const incomeProfileR12Detail = useMemo(
    () =>
      selectedDate === 'summary'
        ? undefined
        : incomeProfileR12DataFiltered.find(
            (month) => `${month.date}` === selectedDate,
          )?.assets,
    [incomeProfileR12DataFiltered, selectedDate],
  );

  const incomeProfileR12DetailSorted = useMemo(
    () =>
      compare && incomeProfileR12Detail
        ? [...incomeProfileR12Detail].sort(compare)
        : incomeProfileR12Detail,
    [compare, incomeProfileR12Detail],
  );

  const incomeProfileR12DetailAfterTax = useMemo(
    () =>
      selectedDate === 'summary'
        ? undefined
        : incomeProfileR12DataFiltered
            .find((month) => `${month.date}` === selectedDate)
            ?.assetsAfterTax?.sort(compare),
    [compare, incomeProfileR12DataFiltered, selectedDate],
  );

  const incomeProfileR12DetailAfterTaxSorted = useMemo(
    () =>
      compare && incomeProfileR12DetailAfterTax
        ? [...incomeProfileR12DetailAfterTax].sort(compare)
        : incomeProfileR12DetailAfterTax,
    [compare, incomeProfileR12DetailAfterTax],
  );

  return {
    dateOptions,
    incomeProfileR12Detail: incomeProfileR12DetailSorted,
    incomeProfileR12DetailAfterTax: incomeProfileR12DetailAfterTaxSorted,
    selectedDate,
    setSelectedDate,
  };
}

export function useSorting() {
  const sortOptions: readonly [
    IncomeProfileAssetSortOption,
    ...IncomeProfileAssetSortOption[]
  ] = useMemo(
    () => [
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Alphabetical: A to Z',
        value: 'default',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Alphabetical: Z to A',
        value: 'alphabeticalR',
      },
      {
        compare: (a, b) => b.couponDate - a.couponDate,
        label: 'Coupon Date: High to Low',
        value: 'couponDateDESC',
      },
      {
        compare: (a, b) => a.couponDate - b.couponDate,
        label: 'Coupon Date: Low to High',
        value: 'couponDateASC',
      },
      {
        compare: (a, b) => b.income - a.income,
        label: 'Income: High to Low',
        value: 'incomeDESC',
      },
      {
        compare: (a, b) => a.income - b.income,
        label: 'Income: Low to High',
        value: 'incomeASC',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}

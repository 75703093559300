/* eslint-disable import/no-cycle */
import { Device } from '@ionic-enterprise/identity-vault';
import { FC, useEffect, useState } from 'react';

import LogInForm from 'components/LoginForm';
import UpdateAppOverlay from 'components/UpdateAppOverlay';
import { useUpdateApp } from 'containers/Services/UpdateAppService';

import useConnect from './connect';
import { IonContent, IonPage } from './styles';
import type { Props } from './types';

const Guest: FC<Props> = ({
  onSelectBiometrics,
  onSelectPassword,
  onSelectResetPassword,
}) => {
  const [hasDeviceSecureLock, setHasDeviceSecureLock] =
    useState<boolean>(false);
  const updateApp = useUpdateApp();
  const { loading, handle } = useConnect();

  useEffect(() => {
    void Device.isSystemPasscodeSet().then((passcodeEnabled) => {
      setHasDeviceSecureLock(passcodeEnabled);
    });
  }, []);

  return (
    <IonPage>
      <IonContent>
        {updateApp?.isUpdateAppAvailable && <UpdateAppOverlay />}
        <LogInForm
          loading={loading}
          biometricType="generic"
          hasDeviceSecureLock={hasDeviceSecureLock}
          onSubmit={handle.submit}
          onSelectBiometrics={onSelectBiometrics}
          onSelectPassword={onSelectPassword}
          onSelectResetPassword={onSelectResetPassword}
        />
      </IonContent>
    </IonPage>
  );
};

export default Guest;

import type { FC } from 'react';

import AllocationsList from 'components/AllocationsList';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';

import {
  Button,
  Container,
  Content,
  FilterButton,
  Header,
  Navigation,
  Toolbar,
} from './styles';
import type { Props } from './types';

const AllocationsDesktopTablet: FC<Props> = ({
  allocationChartData,
  balance,
  breadcrumbs,
  groupLinks,
  groups,
  headerColumns,
  onChangeFiltering,
  filterOptions,
  currentFiltering,
  sortApplied,
  onChangeSorting,
  selectedGroupUrl,
  hasEntityFilter,
  url,
}) => {
  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortApplied.titleColumn) {
      onChangeSorting(
        sortApplied.orderOrientation === OrderOrientation.DESCENDENT
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortApplied.titleColumn) {
      onChangeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  const applyFilter = (value: string[]) => {
    onChangeFiltering(value.join());
  };

  return (
    <Container>
      <Header
        chartData={allocationChartData}
        title="Portfolio Balance"
        amount={balance}
      />
      <Navigation
        breadcrumbs={breadcrumbs}
        links={groupLinks}
        activeLink={selectedGroupUrl}
      />
      <Content>
        <Toolbar>
          <Button
            size="small"
            color="secondary"
            href={`${url}/income-projection`}
          >
            Income Projection Table
          </Button>
          <Button
            size="small"
            color="secondary"
            href={`${url}/asset-allocation-history`}
          >
            Asset Allocation History
          </Button>
          {hasEntityFilter && (
            <FilterButton
              onChangeMultiple={applyFilter}
              options={filterOptions.filter((opt) => opt.value !== 'default')}
              placeholder="Entity"
              value={currentFiltering}
              multiple
            />
          )}
        </Toolbar>
        <AllocationsList
          data={groups}
          handleSortByColumn={handleSortByColumn}
          sortApplied={sortApplied}
          headerColumns={headerColumns}
        />
      </Content>
    </Container>
  );
};

export default AllocationsDesktopTablet;

import type { KeySortOfColumn } from 'utils/sort/types';
import type { SortOption } from 'utils/useDataManipulation/useEntitySorting';

import type { PositionByAccountAsset } from './types';

export function getHeaderColumns(nameExtraData: string, categorySlug: string) {
  return [
    {
      titleColumn: 'Custodian',
      keyOrderAsc: 'custodianASC',
      keyOrderDesc: 'custodianDESC',
    },
    {
      titleColumn: 'Entity',
      keyOrderAsc: 'entityASC',
      keyOrderDesc: 'entityDESC',
    },
    {
      titleColumn: 'Account',
      keyOrderAsc: 'accountASC',
      keyOrderDesc: 'accountDESC',
    },
    {
      titleColumn: 'Shares',
      keyOrderAsc: 'sharesASC',
      keyOrderDesc: 'sharesDESC',
    },
    {
      titleColumn: 'Total (%)',
      keyOrderAsc: 'totalASC',
      keyOrderDesc: 'totalDESC',
    },
    {
      titleColumn: 'Market Value',
      keyOrderAsc: 'marketValueASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Cost',
      keyOrderAsc: 'costASC',
      keyOrderDesc: 'costDESC',
    },
    {
      titleColumn: `Avg. Cost per ${
        categorySlug !== 'fixed-income' ? 'Share' : 'Unit'
      }`,
      keyOrderAsc: 'avgCostASC',
      keyOrderDesc: 'avgCostDESC',
    },
    {
      titleColumn: nameExtraData,
      keyOrderAsc: 'extraDataASC',
      keyOrderDesc: 'extraDataDESC',
    },
    {
      titleColumn: 'P/L ($)',
      keyOrderAsc: 'pandlASC',
      keyOrderDesc: 'pandlDESC',
    },
  ] as KeySortOfColumn[];
}

export function getColumnKeySelected(
  title: string,
  order: string,
  nameExtraData: string,
  categorySlug: string,
) {
  const column = getHeaderColumns(nameExtraData, categorySlug).find(
    (col) => col.titleColumn === title,
  );

  return order === 'ASC' ? column?.keyOrderAsc : column?.keyOrderDesc;
}

type AssetSortOption = SortOption<PositionByAccountAsset>;

const sortOptions = (): readonly [AssetSortOption, ...AssetSortOption[]] => [
  {
    compare: (a, b) => a.custodian.localeCompare(b.custodian),
    label: 'Asset: A to Z',
    value: 'custodianDESC',
  },
  {
    compare: (a, b) => b.custodian.localeCompare(a.custodian),
    label: 'Asset: Z to A',
    value: 'custodianASC',
  },
  {
    compare: (a, b) => a.entity.localeCompare(b.entity),
    label: 'Entity: A to Z',
    value: 'entityDESC',
  },
  {
    compare: (a, b) => b.entity.localeCompare(a.entity),
    label: 'Entity: Z to A',
    value: 'entityASC',
  },
  {
    compare: (a, b) => a.account.localeCompare(b.account),
    label: 'Account: A to Z',
    value: 'accountDESC',
  },
  {
    compare: (a, b) => b.account.localeCompare(a.account),
    label: 'Account: Z to A',
    value: 'accountASC',
  },
  {
    compare: (a, b) => b.shares - a.shares,
    label: 'Shares: High to Low',
    value: 'sharesDESC',
  },
  {
    compare: (a, b) => a.shares - b.shares,
    label: 'Shares: Low to High',
    value: 'sharesASC',
  },
  {
    compare: (a, b) => b.percTotal - a.percTotal,
    label: 'Total: High to Low',
    value: 'totalDESC',
  },
  {
    compare: (a, b) => a.percTotal - b.percTotal,
    label: 'Total: Low to High',
    value: 'totalASC',
  },
  {
    compare: (a, b) => b.marketValue - a.marketValue,
    label: 'Market Value: High to Low',
    value: 'default',
  },
  {
    compare: (a, b) => a.marketValue - b.marketValue,
    label: 'Market Value: Low to High',
    value: 'marketValueASC',
  },
  {
    compare: (a, b) => b.cost - a.cost,
    label: 'Cost: High to Low',
    value: 'costDESC',
  },
  {
    compare: (a, b) => a.cost - b.cost,
    label: 'Cost: Low to High',
    value: 'costASC',
  },
  {
    compare: (a, b) => b.cost - a.cost,
    label: 'Avg. Cost per Share: High to Low',
    value: 'avgCostDESC',
  },
  {
    compare: (a, b) => a.cost - b.cost,
    label: 'Avg. Cost per Share: Low to High',
    value: 'avgCostASC',
  },
  {
    compare: (a, b) => (b.extraData || 0) - (a.extraData || 0),
    label: 'Extra Data: High to Low',
    value: 'extraDataDESC',
  },
  {
    compare: (a, b) => (a.extraData || 0) - (b.extraData || 0),
    label: 'Extra Data: Low to High',
    value: 'extraDataASC',
  },
  {
    compare: (a, b) => b.totalProfitAndLoss - a.totalProfitAndLoss,
    label: 'P/L: High to Low',
    value: 'pandlDESC',
  },
  {
    compare: (a, b) => a.totalProfitAndLoss - b.totalProfitAndLoss,
    label: 'P/L: Low to High',
    value: 'pandlASC',
  },
];

export function getSortingData(
  key: string,
  data: readonly PositionByAccountAsset[],
) {
  return (data as PositionByAccountAsset[]).sort(
    sortOptions().find((option) => option.value === key)?.compare,
  );
}

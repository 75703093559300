import styled from 'styled-components';

import { ReactComponent as DefaultVectorDownIcon } from 'assets/icons/vector-down.svg';
import { ReactComponent as DefaultVectorUpIcon } from 'assets/icons/vector-up.svg';

export const Table = styled.div`
  display: grid;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
  > :nth-child(even) {
    background: ${({ theme }) => theme.colors.dark1};
  }
`;

export const Header = styled.div<{
  $hasCouponDate?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $hasCouponDate = false }) =>
    $hasCouponDate ? '2fr 0.6fr 1fr' : 'repeat(2, 1fr)'};
  column-gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.colors.white75};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  text-align: center;
  > :first-child {
    text-align: left;
  }
  > :last-child {
    text-align: right;
  }
`;

export const HeaderTitle = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

export const HeaderCell = styled.div`
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const Row = styled.div<{
  $hasCouponDate?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $hasCouponDate = false }) =>
    $hasCouponDate ? '2fr 0.6fr 1fr' : 'repeat(2, 1fr)'};
  column-gap: 0.5rem;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.white10};
  padding: 1rem 1.5rem;
`;

export const FirstCol = styled.div`
  text-align: left;
  padding: 0 0.25rem;
  font-size: 1rem;
  line-height: 1.0625rem;
`;

export const LastRow = styled(Row)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const LastFirstCol = styled(FirstCol)`
  font-size: 0.875rem;
`;

export const ColCenter = styled.div`
  text-align: center;
`;

export const Col = styled.div`
  text-align: right;
`;

export const VectorDownIcon = styled(DefaultVectorDownIcon)``;

export const VectorUpIcon = styled(DefaultVectorUpIcon)``;

import { Device } from '@ionic-enterprise/identity-vault';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { ACCESS_TOKEN_QUERY_KEY } from 'api/queries/auth/accessToken';
import { useVault } from 'containers/Services/VaultService';

export function useBiometricsControls() {
  const [hasDeviceSecureLock, setHasDeviceSecureLock] =
    useState<boolean>(false);
  const client = useQueryClient();
  const { clearVault, isCreated, isSupported, setupVault } = useVault();

  useEffect(() => {
    void Device.isSystemPasscodeSet().then((passcodeEnabled) => {
      setHasDeviceSecureLock(passcodeEnabled);
    });
  }, []);

  // Ideally the source of this state would be the VaultService, but
  // unfortunately IonToggle doesn't seem to offer a way of having a truly
  // controlled toggle: clicking the toggle will cause the UI to show the
  // toggled state, regardless of whether the checked value is updated (which
  // would be the standard React behaviour).
  // e.preventDefault() doesn't seem to work either so in order to prevent an
  // event we have to toggle the state and toggle it back.
  const [enabled, setEnabled] = useState(false);

  // Since we have duplicate states, we need an one-way synchronization effect.
  useEffect(() => {
    setEnabled(isCreated);
  }, [isCreated, setEnabled]);

  const type = isSupported && hasDeviceSecureLock ? 'Biometrics' : undefined;

  const onToggle = useCallback(async () => {
    if (enabled) {
      setEnabled(false);
      await clearVault();
    } else {
      setEnabled(true);
      const success = await setupVault();

      if (success) {
        // Force an update to the access token query's cache so it is persisted
        // after the vault is set up (this is normally done at log in).
        client.setQueryData(ACCESS_TOKEN_QUERY_KEY, (data) => data);
      } else {
        setEnabled(false);
      }
    }
  }, [clearVault, client, enabled, setEnabled, setupVault]);

  return {
    enabled,
    onToggle,
    type,
  };
}

import { FC, memo, useMemo } from 'react';

import Amount from 'components/Amount';
import BaseFixedIncomeProfileTable from 'components/BaseFixedIncomeProfileTable';
import {
  Col,
  LastFirstCol,
  LastRow,
} from 'components/BaseFixedIncomeProfileTable/styles';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';

import type { Props } from './types';

const DetailFixedIncomeProfileTable: FC<Props> = ({
  data,
  headerColumns,
  sortApplied,
  onChangeSorting,
}) => {
  const rows = useMemo(
    () =>
      data.map((item) => ({
        id: item.id,
        name: item.name,
        couponDate: item.couponDate,
        value: item.income,
      })),
    [data],
  );

  const total = useMemo(
    () => data.reduce((acc, item) => item.income + acc, 0),
    [data],
  );

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortByColumnSelected.titleColumn === sortApplied.titleColumn) {
      onChangeSorting(
        sortApplied.orderOrientation === OrderOrientation.DESCENDENT
          ? sortByColumnSelected.keyOrderAsc
          : sortByColumnSelected.keyOrderDesc,
      );
    } else if (sortByColumnSelected.titleColumn !== sortApplied.titleColumn) {
      onChangeSorting(sortByColumnSelected.keyOrderDesc);
    }
  };

  return (
    <BaseFixedIncomeProfileTable
      headerColumns={headerColumns}
      items={rows}
      lastRow={
        <LastRow>
          <LastFirstCol>Total</LastFirstCol>
          <Col>
            <Amount value={total} />
          </Col>
        </LastRow>
      }
      handleSortByColumn={handleSortByColumn}
      sortApplied={sortApplied}
    />
  );
};

export default memo(DetailFixedIncomeProfileTable);

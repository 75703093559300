/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';

import DetailFixedIncomeProfileTable from 'components/DetailFixedIncomeProfileTable';
import SummaryFixedIncomeProfileTable from 'components/SummaryFixedIncomeProfileTable';
import { OrderOrientation } from 'utils/sort/types';

import useConnect from './connect';
import {
  ActionZone,
  Container,
  ContainerToggle,
  DataDisplayButton,
  FilterButton,
  IncomeProfileR12Chart,
  Label,
  Title,
  Toggle,
  Toolbar,
} from './styles';
import type { Props } from './types';

const IncomeProfileR12: FC<Props> = ({ enabledAfterTax = false }) => {
  const [sortColumn, setSortColumn] = useState<string>('Asset Name');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );

  const {
    dateOptions,
    handle,
    incomeProfileR12,
    incomeProfileR12Detail,
    incomeProfileR12DetailAfterTax,
    selectedDate,
    entitiesOptions,
    accountsOptions,
    filterEntity,
    filterAccount,
    headerColumns,
    currentSorting,
    hideReceivedCoupons,
  } = useConnect();

  useEffect(() => {
    const currentSortingAux = currentSorting ?? 'default';

    const headerColumn = headerColumns.find(
      (col) =>
        col.keyOrderAsc === currentSortingAux ||
        col.keyOrderDesc === currentSortingAux,
    );

    if (headerColumn) {
      setSortColumn(headerColumn.titleColumn);
      setSortOrientation(
        headerColumn.keyOrderAsc === currentSortingAux
          ? OrderOrientation.ASCENDENT
          : OrderOrientation.DESCENDENT,
      );
    }
  }, [headerColumns, currentSorting]);

  return (
    <Container>
      <IncomeProfileR12Chart
        data={incomeProfileR12.map((d) => ({
          date: d.date,
          income: enabledAfterTax ? d.incomeAfterTax : d.income,
        }))}
        withAverage
        selectDate={handle.changeSelectedDate}
      />
      <Toolbar>
        <Title>Monthly Fixed Income Profile</Title>
        <ActionZone>
          <ContainerToggle onClick={handle.onHideReceivedCoupons}>
            <Toggle
              id="hide-received-coupons-toggle"
              checked={hideReceivedCoupons}
              value="hideReceivedCoupons"
              size="small"
            />
            <Label>Hide Past Coupons</Label>
          </ContainerToggle>
          <FilterButton
            onChangeMultiple={handle.onChangeFilteringEntity}
            options={
              entitiesOptions.filter((opt) => opt.value !== 'default') ?? []
            }
            value={filterEntity?.join()}
            placeholder="Entity"
            multiple
            showLeft
            textSmall
          />
          <FilterButton
            onChangeMultiple={handle.onChangeFilteringAccount}
            options={
              accountsOptions.filter((opt) => opt.value !== 'default') ?? []
            }
            value={filterAccount?.join()}
            placeholder="Account"
            multiple
            showLeft
            textSmall
          />
          <DataDisplayButton
            options={dateOptions}
            value={selectedDate}
            onChange={handle.changeSelectedDate}
            placeholder="Summary"
          />
        </ActionZone>
      </Toolbar>
      {incomeProfileR12Detail && incomeProfileR12DetailAfterTax ? (
        <DetailFixedIncomeProfileTable
          data={
            enabledAfterTax
              ? incomeProfileR12DetailAfterTax
              : incomeProfileR12Detail
          }
          headerColumns={headerColumns}
          onChangeSorting={handle.changeSorting}
          sortApplied={{
            titleColumn: sortColumn,
            orderOrientation: sortOrientation as OrderOrientation,
          }}
        />
      ) : (
        <SummaryFixedIncomeProfileTable
          data={incomeProfileR12.map((d) => ({
            id: d.id,
            date: d.date,
            income: enabledAfterTax ? d.incomeAfterTax : d.income,
          }))}
        />
      )}
    </Container>
  );
};
export default IncomeProfileR12;

import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import UpdateAppOverlay from 'components/UpdateAppOverlay';
import Guest from 'containers/Guest';
import { useUpdateApp } from 'containers/Services/UpdateAppService';

import useConnect from './connect';
import { IonContent, IonPage, TermsAndConditionsContent } from './styles';

const TermsAndConditions: FC = () => {
  const updateApp = useUpdateApp();
  const { handle, termsAndConditions } = useConnect();
  const location = useLocation();

  if (location.pathname === '/reset-password') {
    return <Guest />;
  }

  return (
    <IonPage>
      <IonContent>
        {updateApp?.isUpdateAppAvailable && <UpdateAppOverlay />}
        {termsAndConditions && (
          <TermsAndConditionsContent
            content={termsAndConditions}
            onAccept={handle.acceptTermsConditions}
            onReject={handle.logout}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TermsAndConditions;

import { FC, memo } from 'react';

import Amount from 'components/Amount';
import from from 'styles/responsive';
import { formatDate } from 'utils/dates';
import { OrderOrientation } from 'utils/sort/types';
import useMedia from 'utils/useMedia';

import {
  Col,
  ColCenter,
  FirstCol,
  Header,
  HeaderCell,
  HeaderTitle,
  Row,
  Table,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const BaseFixedIncomeProfileTable: FC<Props> = ({
  items,
  lastRow,
  headerColumns,
  handleSortByColumn,
  sortApplied,
}) => {
  const isMobile = !useMedia(from.tablet.query);

  return (
    <Table>
      <Header $hasCouponDate={headerColumns.length === 3}>
        {headerColumns.map((col) => (
          <HeaderCell key={col.titleColumn}>
            <HeaderTitle
              onClick={() =>
                handleSortByColumn ? handleSortByColumn(col) : undefined
              }
            >
              {col.titleColumn}
              {!isMobile &&
                sortApplied?.titleColumn === col.titleColumn &&
                (sortApplied?.orderOrientation ===
                OrderOrientation.DESCENDENT ? (
                  <VectorDownIcon />
                ) : (
                  <VectorUpIcon />
                ))}
            </HeaderTitle>
          </HeaderCell>
        ))}
      </Header>
      {items.map((row) => (
        <Row key={row.id} $hasCouponDate={!!row.couponDate}>
          <FirstCol>{row.name}</FirstCol>
          {row.couponDate && (
            <ColCenter>{formatDate(row.couponDate)}</ColCenter>
          )}
          <Col>
            <Amount value={row.value} />
          </Col>
        </Row>
      ))}
      {lastRow || null}
    </Table>
  );
};

export default memo(BaseFixedIncomeProfileTable);
